import React from 'react'
import PropTypes from 'prop-types'
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TwitterIcon,
  TelegramIcon,
  WhatsappIcon,
} from 'react-share'

import style from '../styles/share.module.css'

const Share = ({ baseUrl, path, title }) => (
  <section className={style.share}> 
    <TwitterShareButton url={`${baseUrl}${path}`} title={`"${title}"`}>
      <TwitterIcon size={32} round />
    </TwitterShareButton>
    <TelegramShareButton url={`${baseUrl}${path}`} title={`"${title}"`}>
      <TelegramIcon size={32} round />
    </TelegramShareButton>
    <WhatsappShareButton url={`${baseUrl}${path}`} title={`"${title}"`}>
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
  </section>
)

Share.propTypes = {
  path: PropTypes.string,
  baseUrl: PropTypes.string,
  title: PropTypes.string,
}

export default Share
